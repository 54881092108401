import React, { Component } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
} from "reactstrap"
import { Link } from "react-router-dom"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withNamespaces } from "react-i18next"

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reports: [
                {
                    title: "Pedidos",
                    iconClass: "bx-copy-alt",
                    description: "1,235",
                },
                {
                    title: "Revenue",
                    iconClass: "bx-archive-in",
                    description: "$35,723",
                },
                {
                    title: "Ticket Promedio",
                    iconClass: "bx-purchase-tag-alt",
                    description: "$16.2",
                },
            ],
            email: [
                { title: "Week", linkto: "#", isActive: false },
                { title: "Month", linkto: "#", isActive: false },
                { title: "Year", linkto: "#", isActive: true },
            ],
            modal: false,
        }
        this.togglemodal.bind(this)
    }

    togglemodal = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }))
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={this.props.t("Dashboard")}
                            breadcrumbItem={this.props.t("Dashboard")}
                        />

                        <Row>
                            <Col xl="12">
                                <Row>
                                    {/* Reports Render */}
                                    {this.state.reports.map((report, key) => (
                                        <Col md="4" key={"_col_" + key}>
                                            <Card className="mini-stats-wid">
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">
                                                                {report.title}
                                                            </p>
                                                            <h4 className="mb-0">
                                                                {
                                                                    report.description
                                                                }
                                                            </h4>
                                                        </Media>
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                            <span className="avatar-title">
                                                                <i
                                                                    className={
                                                                        "bx " +
                                                                        report.iconClass +
                                                                        " font-size-24"
                                                                    }
                                                                ></i>
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>

                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4 float-sm-left">
                                            Pedidos por Mes
                                        </CardTitle>
                                        <div className="float-sm-right">
                                            <ul className="nav nav-pills">
                                                {this.state.email.map(
                                                    (mail, key) => (
                                                        <li
                                                            className="nav-item"
                                                            key={"_li_" + key}
                                                        >
                                                            <Link
                                                                className={
                                                                    mail.isActive
                                                                        ? "nav-link active"
                                                                        : "nav-link"
                                                                }
                                                                to={mail.linkto}
                                                            >
                                                                {mail.title}
                                                            </Link>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                        <div className="clearfix"></div>
                                        <StackedColumnChart />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withNamespaces()(Dashboard)
