import { takeEvery, fork, put, all } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { loginSuccess, apiError } from "./actions"

import ApiService from '../../../data/services/ApiService'

function* loginUser({ payload: { user, history } }) {
    try {
        const response = yield ApiService.login({
            email: user.email,
            password: user.password
        })

        if (!response.data.success) {
            yield put(apiError('invalid login'))
            return
        }

        sessionStorage.setItem("authToken", response.data.token)
        sessionStorage.setItem("authUser", JSON.stringify(response.data.user))
        yield put(loginSuccess(response.data.user))

        history.push("/dashboard")
    } catch (error) {
        yield put(apiError(error))
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        sessionStorage.removeItem("authToken")
        sessionStorage.removeItem("authUser")
        history.push("/login")
    } catch (error) {
        yield put(apiError(error))
    }
}

export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([fork(watchUserLogin), fork(watchUserLogout)])
}

export default authSaga
