import React from 'react'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
    Container,
} from "reactstrap"

import DataTable from './components/Datatable'

const Index = () => {
    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Llamadas"
                        breadcrumbItem={`Llamadas`}
                        parentLink={`/llamadas`}
                    />

                    <DataTable />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Index
