import React from 'react'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
    Container,
} from "reactstrap"

import Form from './components/Pay'

const Create = (props) => {
    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Llamadas"
                        breadcrumbItem={`Asignar URL`}
                        parentLink={`/llamadas`}
                    />

                    <Form id={props.match.params.id} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Create
