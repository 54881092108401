import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import logo from "../../assets/images/logo.svg"
import logoLightPng from "../../assets/images/logo-light.png"
import logoLightSvg from "../../assets/images/logo-light.svg"
// import logoDark from "../../assets/images/logo-dark.png"

//i18n
import { withNamespaces } from "react-i18next"

// Redux Store
import { toggleRightSidebar } from "../../store/actions"

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSearch: false,
        }
        this.toggleMenu = this.toggleMenu.bind(this)
        this.toggleRightbar = this.toggleRightbar.bind(this)
        this.toggleFullscreen = this.toggleFullscreen.bind(this)
    }
    /**
     * Toggle sidebar
     */
    toggleMenu() {
        this.props.toggleMenuCallback()
    }

    /**
     * Toggles the sidebar
     */
    toggleRightbar() {
        this.props.toggleRightSidebar()
    }

    toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                )
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box">
                                <Link to="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logo} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img
                                            src={logo}
                                            alt=""
                                            height="32"
                                        />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img
                                            src={logoLightSvg}
                                            alt=""
                                            height="32"
                                        />
                                    </span>
                                    <span className="logo-lg">
                                        <img
                                            src={logoLightPng}
                                            alt=""
                                            height="34"
                                        />
                                    </span>
                                </Link>
                            </div>

                            <button
                                type="button"
                                onClick={this.toggleMenu}
                                className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                                id="vertical-menu-btn"
                            >
                                <i className="fa fa-fw fa-bars"></i>
                            </button>
                        </div>
                        <div className="d-flex">
                            <div className="dropdown d-none d-lg-inline-block ml-1">
                                <button
                                    type="button"
                                    onClick={this.toggleFullscreen}
                                    className="btn header-item noti-icon waves-effect"
                                    data-toggle="fullscreen"
                                >
                                    <i className="bx bx-fullscreen"></i>
                                </button>
                            </div>

                            <ProfileMenu />
                        </div>
                    </div>
                </header>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = (state) => {
    const { layoutType } = state.Layout
    return { layoutType }
}

export default connect(mapStatetoProps, { toggleRightSidebar })(
    withNamespaces()(Header)
)
