import React, { Component } from "react"
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Card,
    CardBody,
    Button,
    Label,
} from "reactstrap"
import { Link } from "react-router-dom"
import Select from "react-select"
import ApiService from '../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class Create extends Component {
    constructor(props) {
        super(props)
        this.state = {
            roles: {}
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.showToast = this.showToast.bind(this)
        this._clearForm = this._clearForm.bind(this)
    }

    async componentDidMount() {
        await this.getRoles()
        document.getElementById('fullName').focus()
    }

    showToast(title, message, toastType) {
        if (toastType === "info") {
            toastr.info(message, title)
        } else if (toastType === "warning")  {
            toastr.warning(message,title)
        } else if (toastType === "error") {
            toastr.error(message, title)
        } else {
            toastr.success(message, title)
        }
    }

    async getRoles() {
        const result = await ApiService.getRoles()
        let roles = []

        result.data.data.map((role) => {
            const item = {
                value: role.id,
                label: role.name
            }

            return roles.push(item)
        })

        this.setState({ roles: roles })
    }

    async handleSubmit(e) {
        e.preventDefault()
        
        if (!this._validateForm()) {
            this.showToast('Error', 'No olvides llenar los campos', 'error')

            return
        }

        const {
            fullName,
            email,
            password,
            roleId
        } = this.state

        const user = {
            fullName,
            email,
            password,
            roleId
        }

        const response = await ApiService.saveUser(user).catch(error => {
            this.showToast('Error', 'Hubo un problema al crear el usuario', 'error')
        })

        if (response.data.code === 'USERS_EMAIL_KEY') {
            this.showToast('Error', 'Ya existe un usuario con este correo.', 'error')
            document.getElementById('email').focus()

            return
        }

        if (response.data.success) {
            this.showToast('¡Éxito!', 'Usuario creado correctamente', 'success')
        }

        this._clearForm()
    }

    _clearForm() {
        document.getElementById('user-create').reset()
        document.getElementById('fullName').focus()

        this.setState({
            followUp: true
        })
    }

    _validateForm() {
        const { fullName, password, email, roleId } = this.state

        if (!fullName || fullName === undefined) { return false }
        if (!password || password === undefined) { return false }
        if (!email || email === undefined) { return false }
        if (!roleId || roleId === undefined) { return false }

        return true
    }

    onChangeEmail(event) {
        this.setState({
            email: event.target.value,
            wrongEmail: false
        })

        if (!this.validateEmail(event.target.value)) {
            this.setState({wrongEmail: true})
        }
    }

    validateEmail(element) {
        // eslint:disable-next-line:max-line-length
        var emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    
        if (emailRegex.test(element)) { return true }
    
        return false
    }

    render() {
        const roles = this.state.roles

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Usuarios"
                            breadcrumbItem="Agregar"
                            parentLink={`/usuarios`}
                        />

                        <Form id="user-create" method="POST" onSubmit={ this.handleSubmit }>
                            <Row>
                                <Col xs="12">
                                    <Card style={{textAlign: 'right'}}>
                                        <CardBody>
                                            <Link 
                                                className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                                to="/usuarios"
                                            >
                                                Cancelar
                                            </Link>

                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                            >
                                                <i className={`mdi mdi-check`}></i> Guardar Usuario
                                            </Button>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label htmlFor="fullName">
                                                            Nombre
                                                        </Label>
                                                        <Input
                                                            id="fullName"
                                                            name="fullName"
                                                            type="text"
                                                            className="form-control"
                                                            onChange={event => this.setState({fullName: event.target.value})}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label htmlFor="email">
                                                            E-Mail
                                                        </Label>
                                                        <Input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            className="form-control"
                                                            onBlur={event => this.onChangeEmail(event)}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label htmlFor="password">
                                                            Password
                                                        </Label>
                                                        <Input
                                                            id="password"
                                                            name="password"
                                                            type="password"
                                                            className="form-control"
                                                            onChange={event => this.setState({password: event.target.value})}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup className="select2-container">
                                                        <Label className="control-label">
                                                            Rol
                                                        </Label>
                                                        <Select
                                                            classNamePrefix="select2-selection"
                                                            placeholder="Escoger rol..."
                                                            title="Rol"
                                                            options={roles}
                                                            onChange={event => this.setState({roleId: event.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default Create
