import React, { Component } from "react"
import {
    Container
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import RolesTable from './table'

//i18n
import { withNamespaces } from "react-i18next"

class Users extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.props.t("Roles")}
                            breadcrumbItem={this.props.t("Roles")}
                        />

                        <RolesTable />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withNamespaces()(Users)
