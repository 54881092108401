import React from "react"
import { Redirect } from "react-router-dom"

import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Dashboard from "../pages/Dashboard/index"
import Users from '../pages/Users/index'
import UsersCreate from '../pages/Users/create'
import UsersEdit from '../pages/Users/edit'

import Roles from '../pages/Roles/index'
import RolesCreate from '../pages/Roles/create'
import RolesEdit from '../pages/Roles/edit'

import Models from '../pages/Models/Index'
import ModelsCreate from '../pages/Models/Create'
import ModelsEdit from '../pages/Models/Edit'

import CI from '../pages/Calls/Index'
import CC from '../pages/Calls/Create'
import CE from '../pages/Calls/Edit'
import CP from '../pages/Calls/Pay'

const authProtectedRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/usuarios/agregar", component: UsersCreate },
    { path: "/usuarios/editar/:id", component: UsersEdit },
    { path: "/usuarios", component: Users },

    { path: "/roles/agregar", component: RolesCreate },
    { path: "/roles/editar/:id", component: RolesEdit },
    { path: "/roles", component: Roles },

    { path: "/modelos/agregar", component: ModelsCreate },
    { path: "/modelos/editar/:id", component: ModelsEdit },
    { path: "/modelos", component: Models },

    { path: "/llamadas/asignarurl/:id", component: CP },
    { path: "/llamadas/agregar", component: CC },
    { path: "/llamadas/editar/:id", component: CE },
    { path: "/llamadas", component: CI },

    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
]

export { authProtectedRoutes, publicRoutes }
