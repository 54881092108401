import React, { useState, useEffect } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label
} from "reactstrap"

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import ApiService from '../../../data/services/ApiService'

const FormPage = (props) => {
    const [data, setData] = useState(props.data)
    const [name, setName] = useState('')
    const [avatar, setAvatar] = useState('')
    const [description, setDescription] = useState('')
    const [age, setAge] = useState()

    useEffect(() => {
        if (props.data !== undefined) { 
            const { data } = props

            setData(data)
            setName(data.name)
            setDescription(data.description)
            setAvatar(data.avatar)
            setAge(data.age)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, data?.id)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        const data = {
            avatar,
            name,
            description,
            age
        }

        if (props.data !== undefined) { 
            data.id = props.data.id
            const response = await ApiService.updateModel(data).catch(error => {
                toastr.error(`No pude editar a ${name}.`, 'Error')
            })

            if (response.data.success) {
                toastr.success(`${name} fue editada.`, '¡Éxito!')
            }

            return
        }

        const response = await ApiService.createModel(data).catch(error => {
            toastr.error(`No pude crear a ${name}.`, 'Error')
        })

        if (response.data.success) {
            toastr.success(`${name} fue agregada.`, '¡Éxito!')
        }

        _clearForm()
    }

    const _validateForm = () => {
        if (name === '') { return false }

        return true
    }

    const _clearForm = () => {
        document.getElementById('create').reset()
    }

    const _handleFileUpload = (event) => {
        let files = event.target.files
        const types = ['image/png', 'image/jpeg', 'image/gif']

        if (types.every(type => files[0].type !== type)) {
            toastr.error('Solo se aceptan imágenes', 'Error')

            return 
        }

        fileUpload(files[0]).then((response) => {
            setAvatar(response.data.fileName)

            toastr.success('Subí el avatar', '¡Éxito!')
        }).catch(error => {
            console.log('fileUpload', error)
            toastr.error('No pude subir la imagen', 'Error')
        })
    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('file', file)

        return ApiService.uploadModelPhoto(formData)
    }

    return(
        <React.Fragment>
            <Form id="create" method="POST" onSubmit={ handleSubmit }>
                <Row>
                    <Col xs={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> Guardar
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                Nombre *
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={name}
                                                onChange={event => setName(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="name">
                                                Descripción
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={description}
                                                onChange={event => setDescription(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="name">
                                                Edad
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={age}
                                                onChange={event => setAge(event.target.value)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>Avatar</Label>

                                            <Input type="file"
                                                id="image"
                                                onChange={ _handleFileUpload }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default FormPage