import React, { Component } from "react"
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Card,
    CardBody,
    Button,
    Label
} from "reactstrap"
import { Link } from "react-router-dom"
import ApiService from '../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class Edit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            roles: [],
            password: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.showToast = this.showToast.bind(this)
        this._clearForm = this._clearForm.bind(this)
        this.onChangeEmail = this.onChangeEmail.bind(this)
    }

    async componentWillMount() {
        const userId = this.props.match.params.id
        const response = await ApiService.getUserById(userId)

        this.setState({
            id: response.data.data.id,
            fullName: response.data.data.fullName,
            email: response.data.data.email,
            roleId: response.data.data.roleId
        })
    }

    async componentDidMount() {
        await this.getRoles()
        document.getElementById('fullName').focus()
    }

    componentDidUpdate() {
        const { wrongEmail } = this.state

        if (wrongEmail) {
            this.showToast('Error', 'Debes utilizar un correo electrónico válido', 'error')
        }
    }

    showToast(title, message, toastType) {
        if (toastType === "info") {
            toastr.info(message, title)
        } else if (toastType === "warning")  {
            toastr.warning(message,title)
        } else if (toastType === "error") {
            toastr.error(message, title)
        } else {
            toastr.success(message, title)
        }
    }

    async getRoles() {
        const result = await ApiService.getRoles()
        let roles = []

        result.data.data.map((role) => {
            const item = {
                value: role.id,
                label: role.name
            }

            return roles.push(item)
        })

        this.setState({ roles: roles })
    }

    async handleSubmit(e) {
        e.preventDefault()
        
        if (!this._validateForm()) {
            this.showToast('Error', 'No olvides llenar los campos', 'error')

            return
        }

        const {
            id,
            fullName,
            email,
            password,
            roleId
        } = this.state

        const user = {
            id,
            fullName,
            email,
            password,
            roleId
        }

        const response = await ApiService.updateUser(user).catch(error => {
            this.showToast('Error', 'Hubo un problema al editar el usuario', 'error')

            return 
        })

        if (response.data.success) {
            this.showToast('¡Éxito!', 'Usuario editado correctamente', 'success')
        }
    }

    _clearForm() {
        document.getElementById('user-edit').reset()
        document.getElementById('fullName').focus()

        this.setState({
            followUp: true
        })
    }

    _validateForm() {
        if (this.state.fullName === false) { return false }
        if (this.state.email === false) { return false }
        if (this.state.roleId === undefined) { return false }
        if (this.state.wrongEmail) { return false }

        return true
    }

    onChangeEmail(event) {
        this.setState({
            email: event.target.value,
            wrongEmail: false
        })

        document.getElementById("validate-email").style.display = "none";

        if (!this.validateEmail(event.target.value)) {
            this.setState({wrongEmail: true})
            document.getElementById("validate-email").style.display = "block";
        }
    }

    validateEmail(element) {
        // eslint:disable-next-line:max-line-length
        var emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    
        if (emailRegex.test(element)) { return true }
    
        return false
    }

    render() {
        const { roles, email } = this.state

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Usuarios"
                            breadcrumbItem="Editar"
                            parentLink={`/usuarios`}
                        />

                        <Form id="user-edit" method="POST" onSubmit={ this.handleSubmit }>
                            <Row>
                                <Col xs="12">
                                    <Card style={{textAlign: 'right'}}>
                                        <CardBody>
                                            <Link 
                                                className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                                to="/usuarios"
                                            >
                                                Cancelar
                                            </Link>

                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                            >
                                                <i className={`mdi mdi-check`}></i> Editar Usuario
                                            </Button>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label htmlFor="fullName">
                                                            Nombre
                                                        </Label>
                                                        <Input
                                                            id="fullName"
                                                            name="fullName"
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.fullName}
                                                            onChange={event => this.setState({fullName: event.target.value})}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label htmlFor="email">
                                                            E-Mail
                                                        </Label>
                                                        <Input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            className="form-control"
                                                            value={email}
                                                            onChange={event => this.setState({email: event.target.value})}
                                                            onBlur={this.onChangeEmail}
                                                        />
                                                        <div className={this.state.wrongEmail === true ? "invalid-tooltip" : "valid-tooltip"} id="validate-email">
                                                            {this.state.wrongEmail === true ? "Utiliza un correo electrónico válido" : "¡Gracias!"}
                                                        </div>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label htmlFor="password">
                                                            Password
                                                        </Label>
                                                        <Input
                                                            id="password"
                                                            name="password"
                                                            type="password"
                                                            className="form-control"
                                                            onChange={event => this.setState({password: event.target.value})}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup className="select2-container">
                                                        <Label className="control-label">
                                                            Rol
                                                        </Label>
                                                        <select className="custom-select"
                                                            defaultValue={this.state.roleId}
                                                            onChange={(event) => this.setState({ roleId: event.target.value})}>
                                                            <option key="0" value="0">Seleccione un rol... </option> 
                                                            {roles.map((role, i) => {
                                                                let selected = this.state.roleId === role.value ? 'selected' : ''
                                                                return (
                                                                    <option key={i} value={role.value} selected={selected}>{role.label}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default Edit
