import React, { useState, useEffect } from 'react'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
    Container,
} from "reactstrap"

import Form from './components/Form'
import ApiService from '../../data/services/ApiService'

const Create = (props) => {
    const [data, setData] = useState([])

    useEffect(() => {
        (async () => {
            const id = props.match.params.id
            const response = await ApiService.getModelById(id)

            setData(response.data.model)
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Modelos"
                        breadcrumbItem={`Editar Modelo`}
                        parentLink={`/modelos`}
                    />

                    <Form data={data} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Create
