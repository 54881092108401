import React, { Component } from "react"
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Card,
    CardBody,
    Button,
    Label
} from "reactstrap"
import { Link } from "react-router-dom"
import ApiService from '../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class Edit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: false,
            name: false
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.showToast = this.showToast.bind(this)
        this._clearForm = this._clearForm.bind(this)
    }

    async componentWillMount() {
        const roleId = this.props.match.params.id
        const response = await ApiService.getRole(roleId)

        this.setState({
            id: response.data.data.id,
            name: response.data.data.name,
        })
    }

    async componentDidMount() {
        document.getElementById('name').focus()
    }

    showToast(title, message, toastType) {
        if (toastType === "info") {
            toastr.info(message, title)
        } else if (toastType === "warning")  {
            toastr.warning(message,title)
        } else if (toastType === "error") {
            toastr.error(message, title)
        } else {
            toastr.success(message, title)
        }
    }

    async handleSubmit(e) {
        e.preventDefault()
        
        if (!this._validateForm()) {
            this.showToast('Error', 'No olvides llenar los campos', 'error')

            return
        }

        const { id, name } = this.state
        const role = { id, name }
        const response = await ApiService.updateRole(role).catch(error => {
            this.showToast('Error', 'Hubo un problema al editar el rol', 'error')
        })

        if (response.data.success) {
            this.showToast('¡Éxito!', 'Rol editado correctamente', 'success')
        }

        document.getElementById('role-edit').reset()
    }

    _clearForm() {
        document.getElementById('role-edit').reset()
        document.getElementById('name').focus()
    }

    _validateForm() {
        if (this.state.name === false) { return false }

        return true
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title="Roles"
                            breadcrumbItem="Editar"
                        />

                        <Form id="role-edit" method="POST" onSubmit={ this.handleSubmit }>
                            <Row>
                                <Col xs="12">
                                    <Card style={{textAlign: 'right'}}>
                                        <CardBody>
                                            <Link 
                                                className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                                to="/roles"
                                            >
                                                Cancelar
                                            </Link>

                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                            >
                                                <i className={`mdi mdi-check`}></i> Editar Rol
                                            </Button>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label htmlFor="firstName">
                                                            Nombre
                                                        </Label>
                                                        <Input
                                                            id="name"
                                                            name="name"
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.name}
                                                            onChange={event => this.setState({name: event.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default Edit
