import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Input,
    Card,
    CardBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap"

import { Link } from "react-router-dom"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import ApiService from '../../../data/services/ApiService'
import Avatar from '../../../assets/images/avatar-product-placeholder.png'

const Table = (props) => {
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        (async () => {
            await fetchData()
        })()

        async function fetchData() {
            const response = await ApiService.getModels()

            setData(response.data.data)
            setFilteredData(response.data.models)
        }
    }, [])

    const _filterData = async (e) => {
        const keystroke = e.target.value.toLowerCase()
        const filteredData = data.filter((item) => {
            const name = item.name || ''
            const description = item.description || ''
            return name.toLowerCase().includes(keystroke) ||
                description.toLowerCase().includes(keystroke)
        })

        setFilteredData(filteredData)
    }

    const _deleteItem = async (id) => {
        const result = await ApiService.deleteModel(id)
        if (!result.data.success) {
            toastr.error(`No pude eliminar esta modelo`, 'Error')
        }

        let filtered = await data.filter((v) => { return v.id !== id })

        setData(filtered)
        setFilteredData(filtered)

        toastr.success(`Eliminé una modelo`, '¡Éxito!')
    }

    return(
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row className="mb-2">
                        <Col sm="4">
                            <div className="search-box mr-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                    <Input type="text" className="form-control"
                                        placeholder="Buscar ..."
                                        onKeyUp={(e) => _filterData(e)}
                                    />
                                    <i className="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>
                        </Col>
                        <Col sm="8">
                            <div className="text-sm-right">
                                <Link to={`/modelos/agregar`} className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2">
                                    <i className="mdi mdi-plus mr-1"></i> Agregar Modelo
                                </Link>
                            </div>
                        </Col>
                    </Row>

                    <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th>Foto</th>
                                    <th>Nombre</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map(
                                    (item, key) => (
                                        <tr key={"_tr_" + key}>
                                            <td>
                                                {item.avatar && (
                                                    <img width="48" title={item.name} alt={item.name}
                                                    src={`${ApiService.getBaseUrl()}/uploads/models/${item.avatar}`} />
                                                )}

                                                {!item.avatar && (
                                                    <img width="48" title={item.name} alt={item.name}
                                                    src={Avatar} />
                                                )}
                                            </td>
                                            <td>{item.name}</td>

                                            <td>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle href="#" className="card-drop" tag="i">
                                                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <DropdownItem href={`/modelos/editar/${item.id}`}>
                                                            <i className="mdi mdi-pencil font-size-16 text-success mr-2"></i> Editar
                                                        </DropdownItem>
                                                        <DropdownItem href="#"
                                                        onClick={() => {
                                                            _deleteItem(item.id)
                                                        }}>
                                                            <i className="mdi mdi-trash-can font-size-16 text-danger mr-2"></i> Eliminar
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default Table