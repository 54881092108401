import React, { useState } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label
} from "reactstrap"

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import ApiService from '../../../data/services/ApiService'

const Pay = (props) => {
    const [url, setURL] = useState('')
    const [callId] = useState(props.id)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        const data = {
            id: callId,
            meetingUrl: url,
            paid: true
        }

        const response = await ApiService.assignMeetingURL(data).catch(error => {
            toastr.error(`No puede realizar esta operación.`, 'Error')
        })

        if (response.data.success) {
            toastr.success(`URL asignada con éxito.`, '¡Éxito!')
        }

        _clearForm()
    }

    const _validateForm = () => {
        if (url === '') { return false }

        return true
    }

    const _clearForm = () => {
        document.getElementById('create').reset()
    }

    return(
        <React.Fragment>
            <Form id="create" method="POST" onSubmit={ handleSubmit }>
                <Row>
                    <Col xs={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> Asignar
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                URL de Llamada *
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={url}
                                                required
                                                onChange={event => setURL(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default Pay