import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
} from "./actionTypes"

const initialState = {
    error: "dkjkdjfkdjfkdjfk",
    loading: false,
    user: sessionStorage.getItem('authUser')
    // user: {}
}

const login = (state = initialState, action) => {
    const { data } = action

    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                loading: true,
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: data,
                loading: false,
            }
        case LOGOUT_USER:
            state = { ...state }
            break
        case LOGOUT_USER_SUCCESS:
            state = { ...state }
            break
        case API_ERROR:
            state = { ...state, error: action.payload, loading: false }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

export default login
