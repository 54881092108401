import React, { Component } from "react"
import { Card, CardBody, Row, Col, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

import ApiService from '../../data/services/ApiService'
import { Link } from "react-router-dom"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

class LatestTransaction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            filteredUsers: [],
            isSearching: false
        }

        this._deleteUser = this._deleteUser.bind(this)
        this.showToast = this.showToast.bind(this)
        this._filterUsers = this._filterUsers.bind(this)
    }

    showToast(title, message, toastType) {
        if (toastType === "info") {
            toastr.info(message, title)
        } else if (toastType === "warning")  {
            toastr.warning(message,title)
        } else if (toastType === "error") {
            toastr.error(message, title)
        } else {
            toastr.success(message, title)
        }
    }

    async componentDidMount() {
        await this._getUsers()
    }

    async _getUsers() {
        const result = await ApiService.getUsers()
        this.setState({
            users: result.data.data
        })
    }

    async _deleteUser(userId) {
        const result = await ApiService.deleteUser(userId)

        if (!result.data.success) {
            this.showToast('Error', 'Hubo un problema al eliminar el usuario', 'error')
        }

        this.showToast('¡Éxito!', 'Usuario eliminado correctamente', 'success')
        await this._getUsers()
    }

    async _filterUsers(e) {
        const keystroke = e.target.value.toLowerCase()
        const isSearching = keystroke === '' ? false : true
        this.setState({ isSearching })

        const users = this.state.users.filter((item) => {
            const fullName = item.fullName || ''
            const email = item.email || ''
            const role = item.role.name || ''

            return fullName.toLowerCase().includes(keystroke) || 
                email.toLowerCase().includes(keystroke) || 
                role.toLowerCase().includes(keystroke)
        })

        this.setState({ filteredUsers: users })
    }

    render() {
        const users = !this.state.isSearching ? this.state.users : this.state.filteredUsers
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <Row className="mb-2">
                            <Col sm="4">
                                <div className="search-box mr-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                        <Input type="text" className="form-control"
                                            placeholder="Buscar ..."
                                            onKeyUp={(e) => this._filterUsers(e)}
                                        />
                                        <i className="bx bx-search-alt search-icon"></i>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="8">
                                <div className="text-sm-right">
                                    <Link to={`/usuarios/agregar`} className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2">
                                        <i className="mdi mdi-plus mr-1"></i> Agregar Usuario
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        <div className="table-responsive">
                            <table className="table table-centered table-nowrap mb-0">
                                <thead className="thead-light">
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Email</th>
                                        <th>Rol</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(
                                        (user, key) => (
                                            <tr key={"_tr_" + key}>
                                                <td>{user.fullName}</td>
                                                <td>{user.email}</td>
                                                <td>{user.role.name}</td>

                                                <td>
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle href="#" className="card-drop" tag="i">
                                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                            <DropdownItem href={`/usuarios/editar/${user.id}`}>
                                                                <i className="mdi mdi-pencil font-size-16 text-success mr-2"></i> Editar
                                                            </DropdownItem>
                                                            <DropdownItem href="#"
                                                            onClick={() => {
                                                                this._deleteUser(user.id)
                                                            }}>
                                                                <i className="mdi mdi-trash-can font-size-16 text-danger mr-2"></i> Eliminar
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
}

export default LatestTransaction
