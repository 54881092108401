import React, { Component } from "react"

import { Row, Col, CardBody, Card, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import toastr from 'toastr'

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {}

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
    }

    handleValidSubmit(event, values) {
        this.props.loginUser(values, this.props.history)
    }

    componentDidMount() {
        this.props.apiError('')
    }

    componentDidUpdate() {
        const { error } = this.props

        if (error) {
            toastr.error('Usuario/Password incorrecto', `Oop's`)
            this.props.apiError('')
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="bx bx-home h2"></i>
                    </Link>
                </div>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-soft-primary">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">
                                                        ¡Bienvenido/a!
                                                    </h5>
                                                    <p>
                                                        Inicia Sesión.
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img
                                                    src={profile}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <Link to="/">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img
                                                            src={logo}
                                                            alt=""
                                                            className="rounded-circle"
                                                            height="34"
                                                        />
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="p-2">
                                            <AvForm
                                                className="form-horizontal"
                                                onValidSubmit={
                                                    this.handleValidSubmit
                                                }
                                            >
                                                <div className="form-group">
                                                    <AvField
                                                        id="email"
                                                        name="email"
                                                        label="Email"
                                                        value=""
                                                        className="form-control"
                                                        placeholder="sansa@call-ladies.com"
                                                        type="email"
                                                        required
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <AvField
                                                        id="password"
                                                        name="password"
                                                        label="Password"
                                                        value=""
                                                        type="password"
                                                        required
                                                        placeholder="******"
                                                    />
                                                </div>

                                                <div className="mt-3">
                                                    <button
                                                        className="btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        Iniciar Sesión
                                                    </button>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>
                                        © {new Date().getFullYear()} Call Ladies, Inc.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = (state) => {
    const { error } = state.Login
    return { error }
}

export default withRouter(
    connect(mapStatetoProps, { loginUser, apiError })(Login)
)
