import ApiHelper from '../../helpers/ApiHelper'
export class ApiService {
    getBaseUrl () {
        return ApiHelper.getBaseUrl()
    }

    /// USERS
    async login(login) {
        return ApiHelper.post(`/v1/users/login`, login)
    }

    async saveUser(user) {
        return ApiHelper.post(`/v1/users/save`, user)
    }

    async getUsers() {
        return ApiHelper.get(`/v1/users/get`)
    }

    async getUserById(userId) {
        return ApiHelper.get(`/v1/users/get-by-id/${userId}`)
    }

    async deleteUser(id) {
        return ApiHelper.delete(`/v1/users/delete/${id}`)
    }

    async updateUser(user) {
        return ApiHelper.put(`/v1/users/update`, user)
    }

    /// ROLES
    async getRoles() {
        return ApiHelper.get(`/v1/role/get`)
    }

    async getRole(roleId) {
        return ApiHelper.get(`/v1/role/get-by-id/${roleId}`)
    }

    async createRole(role) {
        return ApiHelper.post(`/v1/role/save`, role)
    }

    async updateRole(role) {
        return ApiHelper.put(`/v1/role/update`, role)
    }

    async deleteRole(id) {
        return ApiHelper.delete(`/v1/role/delete/${id}`)
    }

    // Models
    async getModels() {
        return ApiHelper.get(`/v1/model/get`)
    }

    async getModelById(id) {
        return ApiHelper.get(`/v1/model/get-by-id/${id}`)
    }

    async deleteModel(id) {
        return ApiHelper.delete(`/v1/model/delete/${id}`)
    }

    async createModel(model) {
        return ApiHelper.post(`/v1/model/save`, model)
    }

    async updateModel(model) {
        return ApiHelper.put(`/v1/model/update`, model)
    }

    async uploadModelPhoto(data) {
        return ApiHelper.postWithFiles(`/v1/model/upload`, data)
    }

    // Calls
    async getCalls() {
        return ApiHelper.get(`/v1/call/get`)
    }

    async getCallsByStatus(status) {
        return ApiHelper.get(`/v1/call/get-by-status/${status}`)
    }

    async assignMeetingURL(data) {
        return ApiHelper.put(`/v1/call/assign-meeting`, data)
    }
}

export default new ApiService()