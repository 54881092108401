import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Input,
    Card,
    CardBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap"

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import ApiService from '../../../data/services/ApiService'

const Table = (props) => {
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        (async () => {
            await fetchData()
        })()

        async function fetchData() {
            const response = await ApiService.getCalls()

            setData(response.data.calls)
            setFilteredData(response.data.calls)
        }
    }, [])

    const _filterData = async (e) => {
        const keystroke = e.target.value.toLowerCase()
        const filteredData = data.filter((item) => {
            const name = item.name || ''
            const email = item.description || ''
            return name.toLowerCase().includes(keystroke) ||
                email.toLowerCase().includes(keystroke)
        })

        setFilteredData(filteredData)
    }

    const _deleteItem = async (id) => {
        const result = await ApiService.deleteModel(id)
        if (!result.data.success) {
            toastr.error(`No pude eliminar esta modelo`, 'Error')
        }

        let filtered = await data.filter((v) => { return v.id !== id })

        setData(filtered)
        setFilteredData(filtered)

        toastr.success(`Eliminé una modelo`, '¡Éxito!')
    }

    return(
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row className="mb-2">
                        <Col sm={12}>
                            <div className="search-box mr-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                    <Input type="text" className="form-control"
                                        placeholder="Buscar ..."
                                        onKeyUp={(e) => _filterData(e)}
                                    />
                                    <i className="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th>Nombre</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map(
                                    (item, key) => (
                                        <tr key={"_tr_" + key}>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.paid ? 'Pagada' : 'No Pagada'}</td>

                                            <td>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle href="#" className="card-drop" tag="i">
                                                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        {!item.paid && (
                                                            <DropdownItem href={`/llamadas/asignarurl/${item.id}`}>
                                                                <i className="mdi mdi-pencil font-size-16 text-success mr-2"></i> Asignar URL
                                                            </DropdownItem>
                                                        )}
                                                        <DropdownItem href="#"
                                                        onClick={() => {
                                                            _deleteItem(item.id)
                                                        }}>
                                                            <i className="mdi mdi-trash-can font-size-16 text-danger mr-2"></i> Eliminar
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default Table